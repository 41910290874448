import classNames from 'classnames';
import type { JSX } from 'react';
import IconError from '../../assets/svg/error.svg';
import styles from './ErrorMessage.css';

export type ErrorMessageProps = {
  children: React.ReactNode;
  isAccent?: boolean;
  isInputError?: boolean;
};

/**
 * Displays an error message
 * @param children      text to render
 * @param isAccent      whether the text is emphasized
 * @param isInputError  whether the error is linked to an input
 */
function ErrorMessage({
  children,
  isAccent = false,
  isInputError = false,
}: ErrorMessageProps): JSX.Element {
  return (
    <div
      data-testid="errorMessageContainer"
      className={classNames({
        [styles.errorMessage__inputError!]: isInputError,
      })}
    >
      {isInputError && (
        <IconError
          data-testid="errorMessageIcon"
          className={styles.errorMessage__icon}
        />
      )}
      <p
        data-testid="errorMessageText"
        className={
          isAccent ? styles.errorMessage__accent : styles.errorMessage__text
        }
      >
        {children}
      </p>
    </div>
  );
}

export default ErrorMessage;
