import { Template } from '@canalplus/sdk-hodor';
import classNames from 'classnames';
import type { JSX } from 'react';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import { useIsFrom } from '../../../components/Page/useIsFrom';
import { useTranslation } from '../../../lang';
import stylesTemplate from './ContentGridTemplate.css';

type ContentGridErrorProps = {
  message?: string;
};

function ContentGridError({ message }: ContentGridErrorProps): JSX.Element {
  const { t } = useTranslation();
  const isFromDetail = useIsFrom(Template.DetailPage);

  return (
    <section
      className={classNames(stylesTemplate.contentGridTemplate, {
        [stylesTemplate['contentGridTemplate--detailV5']!]: isFromDetail,
      })}
    >
      <div className={stylesTemplate.contentGrid__error}>
        <ErrorMessage>{message ?? t('ErrorMessages.noContent')}</ErrorMessage>
      </div>
    </section>
  );
}

export default ContentGridError;
